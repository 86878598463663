import { css } from 'styled-components';
import { TitleStyle, OuterPadding, AttentionFont } from './style';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;

// --------------------
// 各セクションの共通要素
// --------------------
export const SecOuter = css`
	${OuterPadding}
	padding: 50px 10px;
	text-align: center;
	color: ${v.color_base};
	@media ${v.media_sm} {
		padding: 50px 20px;
	}
	@media ${v.media_md} {
		padding: 100px 30px;
	}
	@media ${v.media_lg} {
		padding: 100px 20px;
	}
`;
export const SecWrapper = css`
	@media ${v.media_md} {
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;
export const SecTitle = css`
	${TitleStyle}
	margin-bottom: 30px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
export const SecImg = css`
	width: 100%;
	max-width: 550px;
	height: auto;
	margin-bottom: 30px;
	@media ${v.media_md} {
		width: 45%;
		margin-bottom: 0;
	}
`;
export const SecAttention = css`
	${AttentionFont}
	margin: 10px auto 20px;
	@media ${v.media_md} {
		margin: 20px auto 30px;
	}
`;
export const SecResultImgOuter = css`
	display: flex;
	justify-content: center;
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
`;
export const SecResultImg = css`
	width: 48%;
	max-width: 300px;
	height: auto;
	@media ${v.media_md} {
		max-width: 250px;
	}
`;
