import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { Break, At } from '../style';
import { SecOuter } from '../style-section';

import AtmarkImg from '../../img/atmark.png';
import OtherImg1 from '../../img/home/home_other_01.svg';

const Outer = styled.section`
	${SecOuter}
	color: ${v.color_txt};
	p {
		margin-bottom: 10px;
	}
`;
const Img = styled.img`
	width: 100%;
	max-width: 800px;
	height: auto;
	margin-bottom: 30px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;

const HomeOther = () => {
	return (
		<Outer id="other">
			<Img src={OtherImg1} alt="" width="320.38" height="218.73" />
			<p>
				今までの経営経験を活かし、
				<Break>起業相談やWebやSNSの対策など</Break>
				<Break>情報にまつわるアドバイスも</Break>
				<Break>承っております。</Break>
			</p>
			<p>
				WEBサイト製作（ホームページ制作）、
				<Break>動画製作、フォト撮影など</Break>
				<Break>クリエイターの仕事も受注可能です。</Break>
			</p>
			<p>
				また地域創生の取り組みとして、
				<Break>移住に関するご相談にも</Break>
				<Break>ご協力させていただきます。</Break>
			</p>
			<p>
				お気軽にメール（info
				<At src={AtmarkImg} alt="@" width="16" height="17" />
				h-kikaku.com）<Break>からご相談ください。</Break>
			</p>
		</Outer>
	);
};
export default HomeOther;
