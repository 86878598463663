import React from 'react';
import FooterAccess from './footer-access';
import FooterFacebook from './footer-facebook';
import FooterInsta from './footer-insta';

const Footer = () => {
	return (
		<React.Fragment>
			<FooterFacebook />
			<FooterInsta />
			<FooterAccess />
		</React.Fragment>
	);
};
export default Footer;
