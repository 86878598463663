import React, { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import { scrollWithOffset } from '../scrollWithOffset';
import styled from 'styled-components';
import stylevs from '../../style-variables.json';
const v = stylevs.var;
import Logo from '../../img/logo_01.png';
import LogoLong from '../../img/logo_02.png';
import { BgGradation } from '../style';
import { IconFb, IconInsta } from '../icon';

const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	width: 100%;
	padding: 10px 15px;
	text-transform: uppercase;
	font-weight: ${v.font_weight_bold};
	line-height: 1;
	@media ${v.media_lg} {
		align-items: center;
	}
`;
const GnavBtn = styled.button`
	position: relative;
	display: inline-block;
	appearance: none;
	border: none;
	cursor: pointer;
	z-index: 9999;
	width: 45px;
	height: 45px;
	border-radius: 5px;
	outline: none;
	background-color: ${v.color_sub};
	background-color: transparent;
	&:hover,
	&:focus {
		span::before {
			transform: rotate(10deg);
		}
		span::after {
			transform: rotate(-10deg);
		}
	}
	/* メニューOPEN時の<Line>の動き */
	&[aria-expanded='true'] span {
		background-color: transparent;
		background: transparent;
	}
	&[aria-expanded='true'] span::before,
	&[aria-expanded='true'] span::after {
		top: 0;
		background-color: ${v.color_txt};
	}
	&[aria-expanded='true'] span::before {
		transform: rotate(45deg);
	}
	&[aria-expanded='true'] span::after {
		transform: rotate(-45deg);
	}
	@media ${v.media_lg} {
		display: none;
	}
`;
// ハンバーガーボタンの線
const Line = styled.span`
	${BgGradation}
	position: absolute;
	/* 高さの相殺 */
	top: calc(50% - 2px);
	left: 50%;
	transform: translate(-50%);
	width: 22px;
	height: 2px;
	border-radius: 1px;
	transition: all 0.3s;
	::before,
	::after {
		${BgGradation}
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		transition: inherit;
	}
	::before {
		top: -8px;
	}
	::after {
		top: 8px;
	}
`;
const Nav = styled.nav`
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 0;
	right: 0;
	width: 100%;
	height: 100vh;
	padding: 50px 10px;
	margin: 0 auto;
	background-color: rgba(255, 255, 255, 0.8);
	@media ${v.media_sm} {
		width: 45%;
	}
	@media ${v.media_md} {
		width: 30%;
	}
	@media ${v.media_lg} {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 1366px;
		height: auto;
		padding: 15px 15px;
		background-color: transparent;
	}
	@media ${v.media_xl} {
		padding: 15px 50px;
	}
	&[aria-expanded='true'] {
		display: flex;
	}
`;
const List = styled.ul`
	display: flex;
	flex-direction: column;
	align-items: center;
	&:first-of-type {
		order: 1;
	}
	&:last-of-type {
		order: 3;
	}
	@media ${v.media_lg} {
		flex-direction: row;
		/* ロゴとのレイアウト調整用margin */
		margin-bottom: 15px;
	}
	li {
		margin-bottom: 10px;
		@media ${v.media_lg} {
			margin-bottom: 0;
			&:not(:last-child) {
				margin-right: 20px;
			}
		}
		@media ${v.media_xl} {
			&:not(:last-child) {
				margin-right: 35px;
			}
		}
	}
`;

// Navリンクの共通スタイル
const StyledLink = styled(HashLink)`
	display: inline-block;
	position: relative;
	color: ${v.color_txt};
	text-align: center;
	margin-bottom: 10px;
	text-decoration: none;
	@media ${v.media_lg} {
		margin-bottom: 0;
	}
	@media all and (any-hover: hover) {
		&:hover {
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -10px;
				width: 80%;
				height: 2px;
				${BgGradation}
			}
		}
	}
`;
const SPLogoLink = styled(HashLink)`
	img {
		width: 60px;
		height: auto;
	}
	@media ${v.media_lg} {
		display: none;
	}
`;
const LogoLink = styled(HashLink)`
	margin-bottom: 30px;
	img {
		width: 200px;
		height: auto;
	}
	@media ${v.media_lg} {
		margin-bottom: 0;
	}
	@media all and (any-hover: hover) {
		&:hover {
			opacity: 0.6;
		}
	}
`;
const Wrapper = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
`;
const StyledIcon = styled(Link)`
	&:first-of-type {
		margin-right: 30px;
		@media ${v.media_md} {
			margin-right: 20px;
		}
	}
	@media all and (any-hover: hover) {
		&:hover {
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);
				bottom: -10px;
				width: 80%;
				height: 2px;
				${BgGradation}
			}
		}
	}
`;

// 「メニューを開閉する」テキストの非表示、スクリーンリーダー読み込ませるため(display: noneは読み込まれない)
const VisuallyHidden = styled.span`
	position: absolute;
	width: 1px;
	height: 1px;
	overflow: hidden;
	clip: rect(0 0 0 0);
`;

const Gnav = () => {
	const [isOpen, setOpen] = useState(false);
	const toggle = () => {
		setOpen(!isOpen);
	};
	// navmenuが開いている時にメニューリンクのどれかをクリックした時メニューを閉じる
	const closeNav = () => {
		if (isOpen === true) {
			setOpen(false);
		}
	};

	return (
		<Header>
			<SPLogoLink
				onClick={closeNav}
				smooth
				to="/#"
				scroll={(el) => scrollWithOffset(el)}
			>
				<img src={Logo} alt="原田企画" width="60" height="60" />
			</SPLogoLink>
			<GnavBtn
				type="button"
				id="GnavBtn"
				aria-controls="Gnav"
				aria-expanded={isOpen}
				onClick={toggle}
			>
				<Line>
					<VisuallyHidden>メニューを開閉する</VisuallyHidden>
				</Line>
			</GnavBtn>
			<Nav aria-controls="Gnav" aria-expanded={isOpen}>
				<LogoLink
					onClick={closeNav}
					smooth
					to="/#"
					scroll={(el) => scrollWithOffset(el)}
				>
					<img src={LogoLong} alt="原田企画" width="200" height="68" />
				</LogoLink>
				<List>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#streaming"
							scroll={(el) => scrollWithOffset(el)}
						>
							<p>配信サポート</p>
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#rental"
							scroll={(el) => scrollWithOffset(el)}
						>
							<p>機材レンタル</p>
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#signboard"
							scroll={(el) => scrollWithOffset(el)}
						>
							<p>看板制作・設置</p>
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#event"
							scroll={(el) => scrollWithOffset(el)}
						>
							<p>イベント企画・運営</p>
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#other"
							scroll={(el) => scrollWithOffset(el)}
						>
							<p>その他ご相談・お問い合わせ</p>
						</StyledLink>
					</li>
					<li>
						<StyledLink
							onClick={closeNav}
							smooth
							to="/#access"
							scroll={(el) => scrollWithOffset(el)}
						></StyledLink>
					</li>
					<li>
						<Wrapper>
							<StyledIcon
								as="a"
								href="https://www.facebook.com/h.kikaku/"
								target="_blank"
								rel="noopener noreferrer"
								onClick={closeNav}
							>
								<IconFb />
							</StyledIcon>
							<StyledIcon
								as="a"
								href="https://www.instagram.com/haradakikaku/"
								target="_blank"
								rel="noopener noreferrer"
								onClick={closeNav}
							>
								<IconInsta />
							</StyledIcon>
						</Wrapper>
					</li>
				</List>
			</Nav>
		</Header>
	);
};
export default Gnav;
