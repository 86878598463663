import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { LeadTitleStyle, Break } from '../style';
import FirstviewImg1 from '../../img/home/home_firstview_01.svg';

const Outer = styled.div`
	max-width: 1366px;
	padding-top: 15vh;
	padding-bottom: 65vw;
	margin: 0 auto 50px;
	background-image: url(${FirstviewImg1});
	background-repeat: no-repeat;
	background-position: bottom right;
	background-size: 80%;
	@media ${v.media_sm} {
		padding-bottom: 35vw;
		background-size: 60%;
	}
	@media ${v.media_md} {
		margin-bottom: 100px;
	}
	@media ${v.media_lg} {
		padding-top: 20vh;
	}
	@media ${v.media_xl} {
		height: 90vh;
		padding-bottom: 0;
	}
`;
const Wrapper = styled.div`
	margin-left: 10px;
	@media ${v.media_md} {
		margin-left: 5%;
	}
`;
const Title = styled.h1`
	width: fit-content;
	margin-bottom: 15px;
	span {
		${LeadTitleStyle}
	}
	@media ${v.media_lg} {
	}
`;
const Attention = styled.p`
	margin-bottom: 5px;
`;

const HomeFirstview = () => {
	return (
		<Outer>
			<Wrapper>
				<Title>
					<span>ひとまず応相談！</span>
					<br />
					<span>あなたのお悩み解決します</span>
				</Title>
				<Attention>
					原田企画はあなたのお悩みに<Break>真摯に向き合います。</Break>
				</Attention>
				<Attention>お困りごとはなんでもご相談ください。</Attention>
			</Wrapper>
		</Outer>
	);
};
export default HomeFirstview;
