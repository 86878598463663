import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { OuterPadding } from '../style';

const Outer = styled.div`
	width: 100%;
	${OuterPadding}
	margin-bottom: 50px;
	text-align: center;
	@media ${v.media_md} {
		margin-bottom: 100px;
	}
`;
const Facebook = styled.div`
	position: relative;
	width: 100%;
	text-align: center;
`;

const FooterFacebook = () => {
	return (
		<Outer>
			<Facebook>
				{/* data-widthとdata-heightに幅・高さを入力、幅のみSPは自動でリサイズ */}
				<div
					className="fb-page"
					data-href="https://www.facebook.com/h.kikaku/"
					data-tabs="timeline"
					data-width="600"
					data-height="600"
					data-small-header="true"
					data-adapt-container-width="true"
					data-hide-cover="false"
					data-show-facepile="false"
				>
					<blockquote
						cite="https://www.facebook.com/h.kikaku/"
						className="fb-xfbml-parse-ignore"
					>
						<a href="https://www.facebook.com/h.kikaku/">原田企画</a>
					</blockquote>
				</div>
			</Facebook>
		</Outer>
	);
};
export default FooterFacebook;
