import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import { HashLink } from 'react-router-hash-link';
import {
	PageOuterStyle,
	TitleStyle,
	Break,
	OuterPadding,
	BtnOuter,
	BtnStyle,
	SubTitleFont,
} from '../components/style';

const Outer = styled.main`
	${PageOuterStyle}
`;
const Wrapper = styled.div`
	${OuterPadding}
	max-width: 1000px;
	margin: 0 auto;
`;
const Title = styled.h1`
	${TitleStyle}
	margin-bottom: 30px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const Text = styled.div`
	margin-bottom: 50px;
`;
const Btn = styled(HashLink)`
	${BtnStyle}
	max-width: 380px;
	height: 120px;
	${SubTitleFont};
	&:first-child {
		margin-bottom: 20px;
	}
	@media ${v.media_md} {
		height: 200px;
		margin: 0 auto;
		&:first-child {
			margin-bottom: 0px;
		}
	}
	@media all and (any-hover: hover) {
		&:hover {
			background-color: ${v.color_base};
			border-color: ${v.color_sub};
		}
	}
`;

const Rental = () => {
	return (
		<Outer>
			<Title>レンタルについて</Title>
			<Wrapper>
				<Text>
					<p>
						※使用状況によってはお貸しできない
						<Break>場合がありますのでご了承ください</Break>
					</p>
					<p>
						※レンタルは徳島県吉野川市で<Break>受け渡しできる方に限ります</Break>
					</p>
					<p>
						※レンタルについてのお問い合わせは
						<Break>
							<a
								target="_blank"
								rel="noreferrer"
								href="https://harada-kikaku.stores.jp/inquiry"
							>
								こちら
							</a>
							よりお願いいたします
						</Break>
					</p>
				</Text>
				<BtnOuter>
					<Btn to="/streaming#">配信機材</Btn>
					<Btn to="/other#">その他レンタル品</Btn>
				</BtnOuter>
			</Wrapper>
		</Outer>
	);
};
export default Rental;
