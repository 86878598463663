import React from 'react';
import smoothscroll from 'smoothscroll-polyfill';
import HomeFirstview from '../components/home/home-firstview';
import HomeAbout from '../components/home/home-about';
import HomeStreaming from '../components/home/home-streaming';
import HomeRental from '../components/home/home-rental';
import HomeSignboard from '../components/home/home-signboard';
import HomeEvent from '../components/home/home-event';
import HomeMovie from '../components/home/home-movie';
import HomeCalender from '../components/home/home-calender';
import HomeOther from '../components/home/home-other';

const Home = () => {
	smoothscroll.polyfill();
	return (
		<main>
			<HomeFirstview />
			<HomeAbout />
			<HomeStreaming />
			<HomeRental />
			<HomeSignboard />
			<HomeEvent />
			<HomeMovie />
			<HomeCalender />
			<HomeOther />
		</main>
	);
};
export default Home;
