import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { OuterStyle, OuterPadding, Break, AttentionFont } from '../style';
import Video_01 from '../../img/home/home_movie.mp4';

const Outer = styled.div`
	${OuterStyle}
	${OuterPadding}
	margin: 50px auto 0;
	text-align: center;
	@media ${v.media_md} {
		margin: 200px auto 100px;
	}
`;
const Video = styled.div`
	width: 100%;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 10px;
	@media ${v.media_md} {
		margin-bottom: 20px;
	}
	video {
		width: 100%;
		max-width: 1000px;
	}
`;
const Attention = styled.p`
	${AttentionFont}
`;
const TxtSmall = styled.span`
	font-size: 1.2rem;
	@media ${v.media_md} {
		font-size: 1.4rem;
	}
`;

const HomeMovie = () => {
	return (
		<Outer>
			<Video>
				<video controls autoPlay playsInline muted src={Video_01}></video>
			</Video>
			<Attention>
				運営協力例（Green Fes 2022）<TxtSmall>2022.3.8更新</TxtSmall>
			</Attention>
			<Attention>
				PA、誘導、管理、看板制作などを
				<Break>お手伝いさせていただきました。</Break>
			</Attention>
		</Outer>
	);
};
export default HomeMovie;
