import React from 'react';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { Break, BtnStyle, AttentionFont } from '../style';
import { SecOuter, SecWrapper, SecTitle, SecImg } from '../style-section';
import RentalImg1 from '../../img/home/home_rental_01.svg';

const Outer = styled.section`
	${SecOuter}
	background-color: ${v.color_sub};
`;
const Wrapper = styled.div`
	${SecWrapper}
	@media ${v.media_md} {
		div {
			order: 1;
		}
	}
`;
const Title = styled.h1`
	${SecTitle}
`;
const Img = styled.img`
	${SecImg}
	@media ${v.media_md} {
		order: 2;
	}
	@media ${v.media_lg} {
		margin-left: 50px;
	}
`;
const AttentionOuter = styled.div`
	margin: 15px auto 30px;
	p {
		${AttentionFont}
		margin-bottom: 5px;
	}
	@media ${v.media_md} {
		margin: 30px auto 50px;
	}
`;
const Btn = styled(HashLink)`
	${BtnStyle}
	height: auto;
	padding: 10px 0;
	margin: 0 auto;
	@media all and (any-hover: hover) {
		&:hover {
			background-color: ${v.color_base};
			border-color: ${v.color_acc};
		}
	}
`;
const HomeRental = () => {
	return (
		<Outer id="rental">
			<Title>
				<span>rental</span>
				<span>機材レンタル</span>
			</Title>
			<Wrapper>
				<Img src={RentalImg1} alt="" width="385.89" height="261.71" />
				<div>
					<p>配信機材やその他備品のレンタルも承っております。</p>
					<p>
						機材の急な故障や<Break>配信のお試しなどに</Break>
						<Break>ご利用ください。</Break>
					</p>
					<AttentionOuter>
						<p>
							※使用状況によってはお貸しできない
							<Break>場合がありますのでご了承ください</Break>
						</p>
						<p>
							※レンタルは徳島県吉野川市で
							<Break>受け渡しできる方に限ります</Break>
						</p>
						<p>
							※レンタルについてのお問い合わせは
							<Break>
								<a
									target="_blank"
									rel="noreferrer"
									href="https://harada-kikaku.stores.jp/inquiry"
								>
									こちら
								</a>
								よりお願いいたします
							</Break>
						</p>
					</AttentionOuter>
					<Btn to="/rental#">機材レンタルについて</Btn>
				</div>
			</Wrapper>
		</Outer>
	);
};
export default HomeRental;
