import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import {
	OuterPadding,
	PageOuterStyle,
	TitleStyle,
	AttentionFont,
	At,
	Break,
} from '../components/style';
import AtmarkImg from '../img/atmark.png';

const Outer = styled.main`
	${OuterPadding}
	${PageOuterStyle}
`;
const Title = styled.h1`
	${TitleStyle}
	margin-bottom: 30px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const Attention = styled.p`
	${AttentionFont}
	opacity: .5;
`;
const StyledAt = styled(At)`
	width: 1.4rem;
	@media ${v.media_md} {
		width: 1.6rem;
	}
`;

const Recruit = () => {
	return (
		<Outer>
			<Title>挑戦者求む！</Title>
			<Attention>
				原田企画では一緒に活動する<Break>仲間を募集しています。</Break>
			</Attention>
			<Attention>
				info
				<StyledAt src={AtmarkImg} alt="@" width="16" height="17" />
				h-kikaku.comまで<Break>お問い合わせください。</Break>
			</Attention>
		</Outer>
	);
};
export default Recruit;
