import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { OuterStyle, OuterPadding, AttentionFont } from '../style';

const Outer = styled.div`
	${OuterStyle}
	${OuterPadding}
	margin: 50px auto 0;
	text-align: center;
	@media ${v.media_md} {
		margin: 200px auto 100px;
	}
`;
const Attention = styled.p`
	${AttentionFont}
`;
const Calender = styled.div`
	position: relative;
	width: 100%;
	height: 350px;
	margin: 50px auto 0;
	iframe {
		width: 100%;
		height: 100%;
	}
	@media ${v.media_sm} {
		max-width: 800px;
		height: 500px;
	}
	@media ${v.media_lg} {
		max-width: 1000px;
		height: 650px;
	}
`;

const HomeMovie = () => {
	return (
		<Outer>
			<Calender>
				<iframe
					src="https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23ffffff&ctz=Asia%2FTokyo&showTitle=0&showPrint=0&showTz=0&showDate=0&showCalendars=0&src=c2VxZDRpMjh0YnRpbTBuazdkc3Z2MTJka2NAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&src=amEuamFwYW5lc2UjaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23F6BF26&color=%230B8043"
					style={{ border: 0 }}
					width="800"
					height="600"
					frameBorder="0"
					scrolling="no"
					title="GoogleCalender"
				></iframe>
			</Calender>
			<Attention>
				<small>
					Safariからご覧になる場合、カレンダーが表示されない場合があります。
				</small>
			</Attention>
			<Attention>
				<small>
					その場合は、GoogleChromeなどの別のブラウザを利用するか
					<br />
					Safariの「サイト越えトラッキングを防ぐ」の設定をOFFにしてください。
				</small>
			</Attention>
			<Attention>
				<small>
					※「サイト越えトラッキングを防ぐ」機能の設定変更は、ご自身の責任において変更してください。
				</small>
			</Attention>
		</Outer>
	);
};
export default HomeMovie;
