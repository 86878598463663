import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from '../scrollWithOffset';
import {
	OuterStyle,
	Break,
	OuterPadding,
	BtnOuter,
	BtnStyle,
	AttentionFont,
	LeadFont,
} from '../style';

const Outer = styled.div`
	${OuterPadding}
	${OuterStyle}
	text-align: center;
`;
const Lead = styled.p`
	${LeadFont}
	margin-bottom: 20px;
	@media ${v.media_md} {
		margin-bottom: 30px;
	}
`;
const Btn = styled(HashLink)`
	${BtnStyle}
	&:not(:last-child) {
		margin-bottom: 20px;
	}
	@media ${v.media_md} {
		&:nth-child(1),
		&:nth-child(3) {
			margin-right: 30px;
		}
	}
	@media all and (any-hover: hover) {
		&:hover {
			background-color: ${v.color_base};
			border-color: ${v.color_sub};
		}
	}
`;
const Attention = styled.span`
	${AttentionFont}
	display: block;
	text-transform: uppercase;
`;
const HomeAbout = () => {
	return (
		<Outer>
			<Lead>
				ライブ配信、配信機材のレンタル、
				<Break>モニュメントや看板制作・設置を含め</Break>
				<Break>イベント企画運営など</Break>
				<br />
				<Break>起業相談、移住相談や</Break>
				<Break>Webにまつわるご相談まで</Break>
				<Break>多岐に渡るご依頼を承っております。</Break>
			</Lead>
			<BtnOuter>
				<Btn smooth to="#streaming" scroll={(el) => scrollWithOffset(el)}>
					<Attention>streaming</Attention>
					配信サポート
				</Btn>
				<Btn smooth to="#rental" scroll={(el) => scrollWithOffset(el)}>
					<Attention>rental</Attention>
					機材レンタル
				</Btn>
				<Btn smooth to="#signboard" scroll={(el) => scrollWithOffset(el)}>
					<Attention>make & set signboard</Attention>
					モニュメント・看板制作・<Break>イルミネーション</Break>
				</Btn>
				<Btn smooth to="#event" scroll={(el) => scrollWithOffset(el)}>
					<Attention>event</Attention>
					イベント企画・運営
				</Btn>
				<Btn smooth to="#other" scroll={(el) => scrollWithOffset(el)}>
					<Attention>other</Attention>
					その他ご相談
				</Btn>
			</BtnOuter>
		</Outer>
	);
};
export default HomeAbout;
