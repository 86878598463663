import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
import { HashLink } from 'react-router-hash-link';
const v = styleVariables.var;
import { OuterPadding, PageOuterStyle, TitleStyle } from '../components/style';

const Outer = styled.main`
	${OuterPadding}
	${PageOuterStyle}
	p {
		margin: 30px 0 15px;
		@media ${v.media_md} {
			margin: 50px 0 15px;
		}
	}
`;
const Title = styled.h1`
	${TitleStyle}
`;

const Notfound = () => {
	return (
		<Outer>
			<Title>ページが見つかりません</Title>
			<p>お探しのページは見つかりませんでした。</p>
			<HashLink to="/">トップページに戻る</HashLink>
		</Outer>
	);
};
export default Notfound;
