import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { Break } from '../style';
import {
	SecOuter,
	SecWrapper,
	SecTitle,
	SecImg,
	SecAttention,
	SecResultImgOuter,
	SecResultImg,
} from '../style-section';
import StreamingImg1 from '../../img/home/home_streaming_01.svg';
import StreamingResultImg1 from '../../img/home/home_streaming_02.jpg';
import StreamingResultImg1_2x from '../../img/home/home_streaming_02@2x.jpg';
import StreamingResultImg2 from '../../img/home/home_streaming_03.jpg';
import StreamingResultImg2_2x from '../../img/home/home_streaming_03@2x.jpg';

const Outer = styled.section`
	${SecOuter}
	background-color: ${v.color_main};
`;
const Wrapper = styled.div`
	${SecWrapper}
`;
const Title = styled.h1`
	${SecTitle}
`;
const Img = styled.img`
	${SecImg}
`;
const Attention = styled.p`
	${SecAttention}
`;
const ResultImgOuter = styled.div`
	${SecResultImgOuter}
`;
const ResultImg = styled.img`
	${SecResultImg}
	&:first-child {
		margin-right: 4%;
	}
`;
const HomeStreaming = () => {
	return (
		<Outer id="streaming">
			<Title>
				<span>streaming</span>
				<span>配信サポート</span>
			</Title>
			<Wrapper>
				<Img src={StreamingImg1} alt="" width="241.71" height="275.58" />
				<div>
					<p>
						徳島県内津々浦々、<Break>Web配信のサポートを承っております。</Break>
					</p>
					<p>
						さまざまな配信実績のある<Break>原田企画にお任せください。</Break>
					</p>
					<Attention>
						※出張サポートは徳島県内に限ります。<Break></Break>
					</Attention>
					<ResultImgOuter>
						<ResultImg
							src={StreamingResultImg1}
							srcSet={`${StreamingResultImg1} 1x, ${StreamingResultImg1_2x} 2x`}
							alt="web配信している様子1"
							width="300"
							height="225"
						/>
						<ResultImg
							src={StreamingResultImg2}
							srcSet={`${StreamingResultImg2} 1x, ${StreamingResultImg2_2x} 2x`}
							alt="web配信している様子2"
							width="300"
							height="225"
						/>
					</ResultImgOuter>
				</div>
			</Wrapper>
		</Outer>
	);
};
export default HomeStreaming;
