import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { Break } from '../style';
import { SecOuter, SecWrapper, SecTitle, SecImg } from '../style-section';
import EventImg1 from '../../img/home/home_event_01.svg';

const Outer = styled.section`
	${SecOuter}
	background-color: ${v.color_acc};
`;
const Wrapper = styled.div`
	${SecWrapper}
	@media ${v.media_md} {
		div {
			order: 1;
		}
	}
`;
const Title = styled.h1`
	${SecTitle}
`;
const Img = styled.img`
	${SecImg}
	@media ${v.media_md} {
		order: 2;
	}
	@media ${v.media_md} {
		margin-left: 30px;
	}
	@media ${v.media_lg} {
		margin-left: 50px;
	}
`;
const MovieOuter = styled.div`
	padding-top: 56.25%;
	width: 100%;
	position: relative;
	margin: 0 auto;
	margin-top: 20px;
	@media ${v.media_md} {
		margin-top: 30px;
	}
	iframe {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0px;
		left: 0px;
	}
`;

const HomeEvent = () => {
	return (
		<Outer id="event">
			<Title>
				<span>Event</span>
				<span>イベント企画・運営</span>
			</Title>
			<Wrapper>
				<Img src={EventImg1} alt="" width="331.3" height="267.4" />
				<div>
					<p>
						イベントの企画・運営も<Break>行っております。</Break>
					</p>
					<p>
						様々なイベント運営の経験から、
						<Break>皆様に愛されるイベント作りをいたします。</Break>
					</p>
					<MovieOuter>
						<iframe
							width="560"
							height="315"
							src="https://www.youtube.com/embed/nYw1OwwKV5Y"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</MovieOuter>
				</div>
			</Wrapper>
		</Outer>
	);
};
export default HomeEvent;
