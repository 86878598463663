import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { OuterStyle, BtnStyle } from '../style';

const Outer = styled.div`
	${OuterStyle}
	padding: 0 10px;
	text-align: center;
	@media ${v.media_md} {
		margin-top: 100px;
	}
`;
const GalleryList = styled.ul`
	display: flex;
	flex-wrap: wrap;
`;
const GalleryItem = styled.li`
	position: relative;
	min-width: 50%;
	width: 50%;
	@media ${v.media_sm} {
		min-width: 25%;
		width: 25%;
	}
	&:before {
		content: '';
		display: block;
		padding-top: 100%;
	}
	@media all and (any-hover: hover) {
		&:hover {
			opacity: 0.7;
		}
	}
`;
const GalleryImg = styled.img`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	object-fit: cover;
`;
const GalleryVideo = styled.video`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	object-fit: cover;
`;
const Btn = styled.a`
	${BtnStyle}
	height: auto;
	padding: 10px 0;
	margin: 50px auto 0;
	@media all and (any-hover: hover) {
		&:hover {
			background-color: ${v.color_base};
			border-color: ${v.color_sub};
		}
	}
`;

const FooterInsta = () => {
	const [posted, setPosted] = useState([]);

	useEffect(() => {
		const user_name = 'haradakikaku'; //ビジネスorクリエイターアカウントの必要あり
		const access_token =
			'EAAIZACTVHZAegBAL2ZCL6LYXQGfbVXDtTyKIzeYVW5MYXXkZC88HOAgN7BVjW02xBWwtqkenWjUgIyA6ZA6M1m8dR4f2CuM9wqg2E8PRuZCrKa7VKHFwEjqdf8K0CiGZB2dsZCJAc0Vk3Kp9wGt0ELvG77RNXgHoMeV6ZBv3A00y5jxZBaNX1Oe7ct';
		const user_id = '17841426658397051';
		const get_count = 4; //取得したい投稿数
		axios
			.get(
				`https://graph.facebook.com/v14.0/${user_id}?fields=business_discovery.username(${user_name}){media_count,media.limit(${get_count}){caption,timestamp,media_url,media_type,permalink,children{id,media_url,media_type}}}&access_token=${access_token}`,
			)
			//通信成功時
			.then((response) => {
				// setPosted(response.data);
				// alert(posted);
				setPosted(response.data.business_discovery.media.data);
			})
			.catch((err) => console.log(err.response));
	}, []);

	// 取得したAPIのチェック ※.then内では確認できないため
	// useEffect(() => {
	// console.log(posted);
	// }, [posted]);

	return (
		<Outer>
			<GalleryList>
				{posted.map((insta) => (
					// media_urlに videoが含まれているとき: videoタグ / 含まれていないとき: imgタグで出力
					// thumbnail_urlはFB側のエラーで出力を吐かないためvideoで出力させる
					<GalleryItem key={insta.id}>
						{/* {console.log(insta)} */}
						<a href={insta.permalink} target="_blank" rel="noopener noreferrer">
							{insta.media_type == 'VIDEO' ? (
								<GalleryVideo
									autoPlay
									playsInline
									muted
									src={insta.media_url}
								></GalleryVideo>
							) : (
								// <p>a</p>
								<GalleryImg src={insta.media_url} alt="インスタ画像" />
							)}
						</a>
					</GalleryItem>
				))}
			</GalleryList>
			<Btn
				href="https://www.instagram.com/haradakikaku/"
				target="_blank"
				rel="noopener noreferrer"
			>
				Instagramはこちらから
			</Btn>
		</Outer>
	);
};

export default FooterInsta;
