import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
import { AttentionFont, BgGradation, At } from '../style';
const v = styleVariables.var;
import AtmarkImg from '../../img/atmark_white.png';
import { IconFb, IconInsta } from '../icon';

const Outer = styled.footer`
	${BgGradation}
	padding: 50px 10px;
	color: ${v.color_base};
	a {
		color: ${v.color_base};
	}
	@media ${v.media_md} {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 70px 0;
	}
`;
const GoogleMap = styled.div`
	position: relative;
	width: 100%;
	height: 300px;
	margin-bottom: 20px;
	iframe {
		width: 100%;
		height: 100%;
	}
	@media ${v.media_md} {
		width: 600px;
		height: 400px;
		margin-right: 5%;
		margin-bottom: 0;
	}
`;
const DataList = styled.dl`
	margin-bottom: 20px;
	@media ${v.media_md} {
		margin-bottom: 10px;
	}
`;
const DataOuter = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 5px;
	font-size: 1.4rem;
	@media ${v.media_md} {
		margin-bottom: 10px;
		font-size: 1.8rem;
	}
	dt {
		font-weight: ${v.font_weight_bold};
		margin-right: 0.5rem;
		@media ${v.media_md} {
			margin-right: 1.5rem;
		}
	}
`;
const StyledLink = styled(HashLink)`
	display: block;
	width: fit-content;
	${AttentionFont}
	text-align: left;
	margin-bottom: 10px;
	@media ${v.media_md} {
		text-align: left;
	}
`;
const Wrapper = styled.div`
	display: flex;
	justify-content: left;
	align-items: center;
`;
const StyledIcon = styled(Link)`
	position: relative;
	&:not(:last-of-type) {
		margin-right: 30px;
		@media ${v.media_md} {
			margin-right: 20px;
		}
	}
	&:before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 1px;
		background-color: ${v.color_base};
	}
	@media all and (any-hover: hover) {
		&:hover {
			&:before {
				height: 0;
			}
		}
	}
`;

const CopyRights = styled.p`
	${AttentionFont}
	margin-top: 50px;
	text-align: center;
	@media ${v.media_md} {
		text-align: left;
	}
`;

const FooterAccess = () => {
	return (
		<Outer id="access">
			<GoogleMap>
				<iframe
					src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3305.34636138752!2d134.22383531521533!3d34.06063438060364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3553ba9efa58a377%3A0x297255b009bcac3a!2z5Y6f55Sw5LyB55S7!5e0!3m2!1sja!2sjp!4v1648086140110!5m2!1sja!2sjp"
					width="600"
					height="400"
					style={{ border: 0 }}
					allowFullScreen=""
					loading="lazy"
					title="GoogleMap"
				></iframe>
			</GoogleMap>
			<div>
				<DataList>
					<DataOuter>
						<dt>営業時間</dt>
						<dd>10時00分～19時00分</dd>
					</DataOuter>
					<DataOuter>
						<dt>定休日</dt>
						<dd>木曜日</dd>
					</DataOuter>
					<DataOuter>
						<dt>住所</dt>
						<dd>
							<p>2023/7/20で下記住所に事務所移転しました。</p>
							<p>吉野川市山川町前川201まちデザビルヂング3F</p>
						</dd>
					</DataOuter>
					<DataOuter>
						<dt>メールアドレス</dt>
						<dd>
							info
							<At src={AtmarkImg} alt="@" width="16" height="17" />
							h-kikaku.com
						</dd>
					</DataOuter>
				</DataList>
				<StyledLink to="/recruit#">求人について</StyledLink>
				<StyledLink to="/privacypolicy#">プライバシーポリシー</StyledLink>
				<Wrapper>
					<StyledIcon
						as="a"
						href="https://www.facebook.com/h.kikaku/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconFb />
					</StyledIcon>
					<StyledIcon
						as="a"
						href="https://www.instagram.com/haradakikaku/"
						target="_blank"
						rel="noopener noreferrer"
					>
						<IconInsta />
					</StyledIcon>
				</Wrapper>
				<CopyRights>
					<small>CopyRights 2022 原田企画</small>
				</CopyRights>
			</div>
		</Outer>
	);
};
export default FooterAccess;
