import React from 'react';
import styled from 'styled-components';
import styleVariables from '../../style-variables.json';
const v = styleVariables.var;
import { Break } from '../style';
import {
	SecOuter,
	SecWrapper,
	SecTitle,
	SecImg,
	SecResultImgOuter,
	SecResultImg,
} from '../style-section';
import SignboardImg1 from '../../img/home/home_signboard_01.svg';
import SignboardResultImg1 from '../../img/home/home_signboard_02.jpg';
import SignboardResultImg1_2x from '../../img/home/home_signboard_02@2x.jpg';
import SignboardResultImg2 from '../../img/home/home_signboard_03.jpg';
import SignboardResultImg2_2x from '../../img/home/home_signboard_03@2x.jpg';
import SignboardResultImg3 from '../../img/home/home_signboard_04.jpg';
import SignboardResultImg3_2x from '../../img/home/home_signboard_04@2x.jpg';
import SignboardResultImg4 from '../../img/home/home_signboard_05.jpg';
import SignboardResultImg4_2x from '../../img/home/home_signboard_05@2x.jpg';

const Outer = styled.section`
	${SecOuter}
	background-color: ${v.color_sub2};
`;
const Wrapper = styled.div`
	${SecWrapper}
`;
const Title = styled.h1`
	${SecTitle}
`;
const Img = styled.img`
	${SecImg}
	@media ${v.media_md} {
		margin-right: 30px;
	}
	@media ${v.media_lg} {
		margin-right: 50px;
	}
`;
const ResultImgOuter = styled.div`
	${SecResultImgOuter}
	flex-wrap: wrap;
	margin-top: 20px;
	@media ${v.media_md} {
		margin-top: 30px;
	}
`;
const ResultImg = styled.img`
	${SecResultImg}
	&:first-child,
	&:nth-child(3) {
		margin-right: 4%;
	}
	&:first-child,
	&:nth-child(2) {
		margin-bottom: 10px;
	}
	@media ${v.media_sm} {
		&:first-child,
		&:nth-child(2) {
			margin-bottom: 20px;
		}
	}
`;
const HomeSignboard = () => {
	return (
		<Outer id="signboard">
			<Title>
				<span>make & set signboard</span>
				<span>
					モニュメント・<Break>看板制作・</Break>
					<Break>イルミネーション</Break>
				</span>
			</Title>
			<Wrapper>
				<Img src={SignboardImg1} alt="" width="341.86" height="223.36" />
				<div>
					<p>
						モニュメントの作成・設置や<Break>看板制作も承ります。</Break>
					</p>
					<p>
						魅力をカタチにした作品で<Break>アピールします。</Break>
					</p>
					<p>
						イベントや店舗の印象付けに<Break>ぜひご相談ください。</Break>
					</p>
					<ResultImgOuter>
						<ResultImg
							src={SignboardResultImg1}
							srcSet={`${SignboardResultImg1} 1x, ${SignboardResultImg1_2x} 2x`}
							alt=""
							width="300"
							height="225"
						/>
						<ResultImg
							src={SignboardResultImg2}
							srcSet={`${SignboardResultImg2} 1x, ${SignboardResultImg2_2x} 2x`}
							alt=""
							width="300"
							height="225"
						/>
						<ResultImg
							src={SignboardResultImg3}
							srcSet={`${SignboardResultImg3} 1x, ${SignboardResultImg3_2x} 2x`}
							alt=""
							width="300"
							height="225"
						/>
						<ResultImg
							src={SignboardResultImg4}
							srcSet={`${SignboardResultImg4} 1x, ${SignboardResultImg4_2x} 2x`}
							alt=""
							width="300"
							height="225"
						/>
					</ResultImgOuter>
				</div>
			</Wrapper>
		</Outer>
	);
};
export default HomeSignboard;
