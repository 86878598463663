import React from 'react';
import Svg from './svg';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;

// gnavアイコンの共通設定
const Icon = styled(Svg)`
	padding: 1px;
	@media ${v.media_md} {
	}
`;
export const IconFb = () => (
	<Icon width="15.25" height="26.5" viewBox="0 0 15.25 26.5" role="img" aria-label="Facebook">
		<path
			fill="currentcolor"
			d="M17,1.25h3.75A.75.75,0,0,1,21.5,2V7a.75.75,0,0,1-.75.75H17a.5.5,0,0,0-.5.5v3h4.25a.75.75,0,0,1,.728.932l-1.25,5a.75.75,0,0,1-.728.568h-3V27a.75.75,0,0,1-.75.75h-5A.75.75,0,0,1,10,27V17.75H7A.75.75,0,0,1,6.25,17V12A.75.75,0,0,1,7,11.25h3v-3A7.008,7.008,0,0,1,17,1.25Zm3,1.5H17a5.506,5.506,0,0,0-5.5,5.5V12a.75.75,0,0,1-.75.75h-3v3.5h3a.75.75,0,0,1,.75.75v9.25H15V17a.75.75,0,0,1,.75-.75h3.164l.875-3.5H15.75A.75.75,0,0,1,15,12V8.25a2,2,0,0,1,2-2h3Z"
			transform="translate(-6.25 -1.25)"
		/>
	</Icon>
);

export const IconInsta = () => (
	<Icon width="26.5" height="26.5" viewBox="0 0 26.5 26.5" role="img" aria-label="Instagram">
		<g transform="translate(0.75 0.75)">
			<path
				fill="currentcolor"
				d="M5-.75H20A5.757,5.757,0,0,1,25.75,5V20A5.757,5.757,0,0,1,20,25.75H5A5.757,5.757,0,0,1-.75,20V5A5.757,5.757,0,0,1,5-.75Zm15,25A4.255,4.255,0,0,0,24.25,20V5A4.255,4.255,0,0,0,20,.75H5A4.255,4.255,0,0,0,.75,5V20A4.255,4.255,0,0,0,5,24.25Z"
			/>
			<path
				fill="currentcolor"
				d="M14.2,7.207a6.95,6.95,0,0,1,1.016.075,6.911,6.911,0,0,1,2.093,13.009,6.941,6.941,0,0,1-3.105.738,6.911,6.911,0,0,1,0-13.823Zm0,12.323a5.411,5.411,0,0,0,5.351-6.2A5.411,5.411,0,0,0,15,8.765a5.411,5.411,0,0,0-4.62,9.179A5.378,5.378,0,0,0,14.206,19.529Z"
				transform="translate(-1.583 -1.589)"
			/>
			<path
				fill="currentcolor"
				d="M.022.75H0A.75.75,0,0,1-.75,0,.75.75,0,0,1,0-.75H.022A.75.75,0,0,1,.772,0,.75.75,0,0,1,.022.75Z"
				transform="translate(19.505 5.665)"
			/>
		</g>
	</Icon>
);
