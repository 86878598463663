import React from 'react';
import styled from 'styled-components';
import styleVariables from '../style-variables.json';
const v = styleVariables.var;
import {
	PageOuterStyle,
	OuterPadding,
	TitleStyle,
	AttentionFont,
	BtnStyle,
	Break,
} from '../components/style';
import ItemImg1 from '../img/other/other_other_01.jpg';
import ItemImg1_2x from '../img/other/other_other_01@2x.jpg';
import ItemImg2 from '../img/other/other_other_02.jpg';
import ItemImg2_2x from '../img/other/other_other_02@2x.jpg';
import ItemImg3 from '../img/other/other_other_03.jpg';
import ItemImg3_2x from '../img/other/other_other_03@2x.jpg';
import ItemImg4 from '../img/other/other_other_04.jpg';
import ItemImg4_2x from '../img/other/other_other_04@2x.jpg';

const Outer = styled.main`
	${PageOuterStyle}
`;
const Wrapper = styled.div`
	${OuterPadding}
	max-width: 1000px;
	margin: 0 auto 50px;
	@media ${v.media_md} {
		padding: 0 20px;
		margin-bottom: 100px;
	}
`;
const Title = styled.h1`
	${TitleStyle}
	margin-bottom: 30px;
	@media ${v.media_md} {
		margin-bottom: 50px;
	}
`;
const StyledSection = styled.section`
	margin-top: 50px;
	text-align: left;
	@media ${v.media_md} {
		margin-top: 100px;
	}
`;
const SectionTitle = styled.h2`
	margin-bottom: 15px;
	font-size: 1.6rem;
	@media ${v.media_md} {
		font-size: 2rem;
	}
`;
const Attention = styled.span`
	${AttentionFont}
	display: block;
	text-transform: uppercase;
`;
const ItemOuter = styled.div`
	display: flex;
	/* flex-direction: column; */
	flex-wrap: wrap;
	justify-content: space-between;
	text-align: center;
	@media ${v.media_md} {
		flex-direction: row;
		justify-content: space-between;
		text-align: center;
	}
`;
const Item = styled.section`
	width: 45%;
	text-align: center;
	&:nth-child(1),
	&:nth-child(2) {
		margin-bottom: 10px;
	}

	@media ${v.media_sm} {
		width: 22%;
	}
	@media ${v.media_md} {
		max-width: 200px;
		&:nth-child(1),
		&:nth-child(2) {
			margin-bottom: 0;
		}
	}
	img {
		width: 100%;
		height: auto;
		max-width: 200px;
	}
`;
const Btn = styled.a`
	${BtnStyle}
	margin: 0 auto;
	@media ${v.media_md} {
	}
	@media all and (any-hover: hover) {
		&:hover {
			background-color: ${v.color_base};
			border-color: ${v.color_sub};
		}
	}
`;

const Other = () => {
	return (
		<Outer>
			<Title>その他レンタル品</Title>
			<Wrapper>
				<p>
					草刈機や墨出し器など配信機材以外の
					<Break>レンタルも行なっております。</Break>
				</p>
				<p>
					商品についてのご相談は
					<Break>
						<a
							target="_blank"
							rel="noreferrer"
							href="https://harada-kikaku.stores.jp/inquiry"
						>
							こちら
						</a>
						からご連絡ください。
					</Break>
				</p>
				<StyledSection>
					<SectionTitle>貸出品例</SectionTitle>
					<ItemOuter>
						<Item>
							<img
								src={ItemImg1}
								srcSet={`${ItemImg1} 1x, ${ItemImg1_2x} 2x`}
								width="200"
								height="200"
								alt="乗用草刈機"
							/>
							<h3>乗用草刈機</h3>
						</Item>
						<Item>
							<img
								src={ItemImg2}
								srcSet={`${ItemImg2} 1x, ${ItemImg2_2x} 2x`}
								width="200"
								height="200"
								alt="ラジコン草刈機"
							/>
							<h3>ラジコン草刈機</h3>
						</Item>
						<Item>
							<img
								src={ItemImg3}
								srcSet={`${ItemImg3} 1x, ${ItemImg3_2x} 2x`}
								width="200"
								height="200"
								alt="墨出し器"
							/>
							<h3>墨出し器</h3>
						</Item>
						<Item>
							<img
								src={ItemImg4}
								srcSet={`${ItemImg4} 1x, ${ItemImg4_2x} 2x`}
								width="200"
								height="200"
								alt="ロールトップテーブル"
							/>
							<h3>ロールトップテーブル</h3>
						</Item>
					</ItemOuter>
				</StyledSection>
				<Attention></Attention>
			</Wrapper>
			<Btn
				target="_blank"
				rel="noreferrer"
				href="https://harada-kikaku.stores.jp/"
			>
				レンタルはこちらから
			</Btn>
		</Outer>
	);
};
export default Other;
